import { Link, Typography } from "components";
import { ComponentType } from "react";
import styled from "styled-components";
import { SwiperSlide } from "swiper/react";
import { COLORS } from "theme";

export const StyledText = styled(Typography)`
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  position: absolute;
  top: 45%;
  z-index: 10;
  cursor: pointer;
`;

export const withSlider = (
  WrappedComponent: ComponentType<any>,
  redirectPath?: string,
  title?: string
) => {
  const SwiperSlideWrapped = (props: any) => {
    return (
      <SwiperSlide key={props.id}>
        {redirectPath ? (
          <Link to={redirectPath} style={{ position: "relative" }}>
            <WrappedComponent {...props} />
            {title && (
              <StyledText
                variant="caption"
                color={COLORS.typography.secondary.main}
              >
                {title}
              </StyledText>
            )}
          </Link>
        ) : (
          <WrappedComponent {...props} />
        )}
      </SwiperSlide>
    );
  };
  return SwiperSlideWrapped;
};
