import styled from "styled-components";
import { Box } from "components/Box";

export const ImageBoxContainer = styled(Box)`
  cursor: pointer;
  height: 100%;
  width: 100%;
`;

export const MasonryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const MasonryColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
