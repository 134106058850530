import { FC, SetStateAction } from "react";
import { Image } from "components/Image";
import {
  ImageBoxContainer,
  MasonryColumn,
  MasonryContainer,
} from "./MasonryImageList.styled";
import { HOST_URL } from "urls/api";

interface Props {
  images: Array<string>;
  setOpenHouseGallery: (value: SetStateAction<boolean>) => void;
  setCurrentImageIndex: (value: SetStateAction<number>) => void;
}

export const MasonryImageList: FC<Props> = ({
  images,
  setOpenHouseGallery,
  setCurrentImageIndex,
}) => {
  const columns: { image: string; index: number }[][] = [[], []];
  images.forEach((image, index) => {
    columns[index % 2].push({ image, index });
  });
  const handleClick = (index: number) => {
    setOpenHouseGallery(true);
    setCurrentImageIndex(index);
  };

  return (
    <>
      <MasonryContainer>
        {columns.map((colImages, colIndex) => (
          <MasonryColumn key={colIndex}>
            {colImages.map(({ image, index }) => (
              <ImageBoxContainer key={`masonry-${index}`}>
                <Image
                  src={`${HOST_URL}${image}`}
                  variant="flexible"
                  onClick={() => handleClick(index)}
                />
              </ImageBoxContainer>
            ))}
          </MasonryColumn>
        ))}
      </MasonryContainer>
    </>
  );
};
