import { FC } from "react";
import { Typography } from "components/Typography";
import { ModalContentProps } from "./UserProfilePreviewModal.d";
import {
  StyledContent,
  StyledMainContent,
  StyledFullName,
  StyledLocation,
  StyledHobby,
  StyledAccessContainer,
  StyledHousesContainer,
  StyledKebabMenu,
  StyledAboutContent,
  StyledFriendRequestButtonContainer,
  StyledButton,
  StyledChip,
  StyledTrustedFriendContainer,
  StyledMailBox,
  StyledTooltip,
} from "./UserProfilePreviewModal.styled";
import { COLORS } from "theme";
import { Image } from "components/Image";
import { MutualFriends } from "components/Friends/MutualFriends";
import { Box } from "components/Box";
import { HOST_URL } from "urls/api";
import { getDefaultAvatar } from "utils/getDefaultAvatar";
import { GenderVariant } from "components/Profile/ProfileForm/ProfileForm.enums";
import { Select } from "components/Select";
import { Button } from "components/Button";
import { HouseItem } from "components/HousesList/HouseItem";
import { AccessVariant } from "../InviteFriendViaEmailModal/InviteFriendViaEmailModal.enums";
import { FriendCardVariant } from "components/Friends/FriendCard/FriendCard.enums";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { FriendRelationType, UserStatus } from "types/enums";
import { useProfile } from "context/Profile";
import {
  AvatarContainer,
  HouseOwnerContainer,
} from "components/Friends/FriendCard/FriendCard.styled";
import { ReactComponent as HouseIcon } from "assets/icons/icon_house.svg";
import { fetchUpdateFriendOptions } from "services/Friends";
import { toast } from "react-toastify";
import { ReactComponent as MailIcon } from "assets/icons/icon_mail.svg";
import { ReactComponent as QuestionMark } from "assets/icons/question_mark.svg";
import { useModal } from "global/hooks/useModal";
import { Modal } from "components/Modal";
import { StyledConfirmationModalContent } from "./UserProfilePreviewModal.styled";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import TrustedFriendModal from "../TrustedFriendModal/TrustedFriendModal";

export const ModalContent: FC<ModalContentProps> = ({
  userInfo,
  discountCurrentValue,
  discountOptionsToShow = [],
  onDiscountSelectChange,
  toggleConfirmModal,
  variant,
  menuOptions,
  sendInvitation,
  acceptInvitation,
}) => {
  const { isShown, toggle } = useModal();
  const profile = useProfile();
  const isHouseOwner = profile?.profileInfo?.is_house_owner || false;
  const isInvitedStatus = userInfo.status === UserStatus.INVITED;
  const isRequestReceivedVariant =
    variant === FriendCardVariant.REQUESTS_RECEIVED;
  const isRequestSentVariant = variant === FriendCardVariant.REQUESTS_SENT;
  const isConnectionsVariant = variant === FriendCardVariant.CONNECTIONS;
  const isPeopleYouMightKnowVariant =
    variant === FriendCardVariant.PEOPLE_YOU_MIGHT_KNOW;
  const fullName = `${userInfo.name} ${userInfo.last_name}`;
  const fullLocation =
    userInfo.profile &&
    [userInfo.profile.city, userInfo.profile.country]
      .filter(Boolean)
      .join(", ");
  const containMutualFriends =
    (userInfo.mutual_relations && !!userInfo.mutual_relations?.relations_num) ||
    false;
  const isChipVisible = isRequestSentVariant || isRequestReceivedVariant;
  const isAddAccessButtonDisabled = userInfo.access_type === AccessVariant.ALL;
  const isTrustedFriendButtonDisabled = userInfo.is_trusted;
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const getChipLabel = () => {
    if (isRequestSentVariant) {
      return `${translation["inviteSentLabel"][selectedLanguage]} ${
        isInvitedStatus
          ? userInfo.sent_friend_invitation?.invitation_sent
          : userInfo.sent_connection_invitation?.invitation_sent
      }`;
    }
    if (isRequestReceivedVariant) {
      return `${translation["inviteSentLabel"][selectedLanguage]} ${
        userInfo.invited_by_you
          ? userInfo.received_friend_invitation?.invitation_sent
          : userInfo.received_connection_invitation?.invitation_sent
      }`;
    }
  };

  const onTrustedFriendClick = async () => {
    const response = await fetchUpdateFriendOptions(
      { is_trusted: true },
      userInfo.id
    );
    if (response.ok) {
      toast.success(
        translation["updateSendSuccessfullyLabel"][selectedLanguage]
      );
    } else {
      toast.error(translation["errorDuringUpdatingLabel"][selectedLanguage]);
    }
    toggle();
  };

  const displayDiscountSelect =
    onDiscountSelectChange &&
    ((isHouseOwner && isRequestSentVariant && isInvitedStatus) ||
      (isHouseOwner && isConnectionsVariant));

  const displayAccessButton =
    isRequestSentVariant || userInfo.invited_by_you || userInfo.is_trusted;

  const displayTrustedFriendButton =
    isHouseOwner &&
    userInfo.invited_by_you &&
    !userInfo.is_house_owner &&
    !userInfo.is_trusted;

  const displayContainer =
    displayAccessButton || displayDiscountSelect || displayTrustedFriendButton;

  return (
    <>
      <StyledContent mt={4.3} mb={3.5} p={2}>
        <TrustedFriendModal
          isShown={isShown}
          toggle={toggle}
          onTrustedFriendClick={onTrustedFriendClick}
        />
        {!isPeopleYouMightKnowVariant && (
          <StyledKebabMenu menuOptions={menuOptions} />
        )}
        <StyledMainContent display="grid" gridGap={2.8}>
          <AvatarContainer>
            <Image
              isRound
              variant="avatarUserPreview"
              src={
                userInfo.profile?.avatar
                  ? `${HOST_URL}${userInfo.profile.avatar}`
                  : getDefaultAvatar(
                      userInfo.gender ?? GenderVariant.GENDER_UNKNOWN
                    )
              }
            />
            {!!userInfo.houses.length && (
              <HouseOwnerContainer>
                <HouseIcon />
              </HouseOwnerContainer>
            )}
          </AvatarContainer>
          <Box display="grid" alignItems="start">
            {isChipVisible && (
              <StyledChip
                label={getChipLabel()}
                color={COLORS.typography.body}
              />
            )}
            <Box display="flex" flexDirection="row">
              <StyledFullName variant="h4">{fullName}</StyledFullName>
              <StyledMailBox display="flex" alignItems="center">
                {userInfo.date_accepted && (
                  <StyledTooltip
                    variant={"bottom"}
                    title={`${translation["invitedByYou"][selectedLanguage]} ${userInfo.date_accepted}`}
                  >
                    <MailIcon />
                  </StyledTooltip>
                )}
              </StyledMailBox>
            </Box>
            {userInfo.profile &&
              (userInfo.profile?.country || userInfo.profile?.city) && (
                <StyledLocation
                  variant="overline"
                  color={COLORS.typography.body}
                >
                  {fullLocation}
                </StyledLocation>
              )}
            <StyledFriendRequestButtonContainer
              display="grid"
              justifyContent={containMutualFriends ? "space-between" : "end"}
              gridGap={2}
              mt={2.5}
            >
              {containMutualFriends &&
                userInfo.mutual_relations?.relations_num && (
                  <MutualFriends
                    images={userInfo.mutual_relations?.avatars ?? []}
                    number={userInfo.mutual_relations?.relations_num}
                  />
                )}
              {(isPeopleYouMightKnowVariant || isRequestReceivedVariant) &&
                userInfo.relation_type !== FriendRelationType.FRIEND && (
                  <StyledButton
                    variant={ButtonVariant.SECONDARY}
                    size={ButtonSize.SMALL}
                    onClick={() =>
                      isPeopleYouMightKnowVariant
                        ? sendInvitation(userInfo.id)
                        : userInfo.received_connection_invitation &&
                          acceptInvitation(
                            userInfo.id,
                            userInfo.received_connection_invitation.id
                          )
                    }
                  >
                    {isPeopleYouMightKnowVariant
                      ? translation["sendRequest"][selectedLanguage]
                      : translation["accept"][selectedLanguage]}
                  </StyledButton>
                )}
            </StyledFriendRequestButtonContainer>
          </Box>
        </StyledMainContent>
        <Box py={2}>
          {userInfo.profile && !!userInfo.profile.about?.length && (
            <StyledAboutContent variant="body" color={COLORS.typography.body}>
              {userInfo.profile.about}
            </StyledAboutContent>
          )}
          {userInfo.profile && !!userInfo.profile.hobbies?.length && (
            <Box display="flex" mt={2} alignItems="center" flexWrap="wrap">
              {userInfo.profile.hobbies.map((hobby, index, array) => (
                <StyledHobby
                  key={hobby.id}
                  variant="caption"
                  color={COLORS.typography.body}
                >
                  {hobby.name} {index !== array.length - 1 && "|"}
                </StyledHobby>
              ))}
            </Box>
          )}
        </Box>
        {displayContainer && (
          <StyledAccessContainer display="grid" alignItems="center">
            {displayDiscountSelect && (
              <Select
                labelInside={
                  translation["addADiscountForYourHouses"][selectedLanguage]
                }
                value={discountCurrentValue}
                options={discountOptionsToShow}
                onChange={onDiscountSelectChange}
                multiple={false}
              />
            )}
            {displayAccessButton && (
              <>
                <Button
                  variant={ButtonVariant.SECONDARY}
                  onClick={toggleConfirmModal}
                  disabled={isAddAccessButtonDisabled}
                >
                  {isAddAccessButtonDisabled
                    ? translation["hasAccessToAllHouses"][selectedLanguage]
                    : translation["applyAccessToAllHouses"][selectedLanguage]}
                </Button>
              </>
            )}
          </StyledAccessContainer>
        )}
        {displayTrustedFriendButton && (
          <StyledTrustedFriendContainer
            display="grid"
            alignItems="center"
            justifyContent="start"
            gridAutoFlow={"column"}
            gridGap={2}
          >
            <Button
              variant={ButtonVariant.SECONDARY}
              onClick={() => toggle()}
              disabled={isTrustedFriendButtonDisabled}
            >
              {isTrustedFriendButtonDisabled
                ? translation["isTrustedFriendLabel"][selectedLanguage]
                : translation["trustedFriends"][selectedLanguage]}
            </Button>
            <Box display="flex">
              <StyledTooltip
                variant="bottom"
                title={
                  translation["giveAbilityToInviteNewUsers"][selectedLanguage]
                }
              >
                <QuestionMark />
              </StyledTooltip>
            </Box>
          </StyledTrustedFriendContainer>
        )}
      </StyledContent>
      {!!userInfo.houses.length && (
        <>
          <Typography variant="h4">
            {translation["ownedPropertiesLabel"][selectedLanguage]}
          </Typography>
          <StyledHousesContainer display="grid" gridGap={2} mt={2.5} pb={10}>
            {userInfo.houses.map((house) => (
              <HouseItem
                key={house.id}
                variant="small"
                houseId={house.id}
                images={house.images}
                images_count={house.images_count}
                title={house.title}
                city={house.location.city}
                country={house.location.country}
                minPrice={house.minimum_price_per_night}
                maxGuests={house.total_guests}
                isFavorite={house.is_bookmarked}
              />
            ))}
          </StyledHousesContainer>
        </>
      )}
    </>
  );
};
