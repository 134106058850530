import { Box } from "components/Box";
import { Button } from "components/Button";
import { ExternalLink } from "components/Link";
import { Typography } from "components/Typography";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const Content = styled(Box)`
  box-sizing: border-box;
  width: ${pxToRem(284)};
  background-color: ${COLORS.typography.secondary.main};
  padding: ${pxToRem(32)} ${pxToRem(14)};


  ${SIZES.media.tablet} {
    width: ${pxToRem(664)};
    padding: ${pxToRem(64)}};
  }

`;

export const ButtonsContainer = styled(Box)`
  margin-top: ${pxToRem(24)};
  grid-auto-flow: row;

  ${SIZES.media.tablet} {
    grid-auto-flow: column;
  }
`;

export const ButtonWrapper = styled(Box)`
  width: 256px;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)`
  padding: ${pxToRem(18)} ${pxToRem(16)};
  ${SIZES.media.tablet} {
    padding: ${pxToRem(18)} ${pxToRem(32)};
  }
`;

export const SvgWrapper = styled(Box)`
  svg {
    color: ${COLORS.typography.headline};
    height: 36px;
    width: auto;
  }
`;

export const StyledDescription = styled(Typography)`
  line-height: 30px;
  font-size: 13px;
  color: ${COLORS.typography.body};
  text-align: center;
  margin-top: ${pxToRem(24)};

  ${SIZES.media.tablet} {
    font-size: 15px;
    margin-top: ${pxToRem(36)};
  }
`;

export const ContactUsExternalLink = styled(ExternalLink)`
  color: ${COLORS.accent.primary};
  text-decoration: underline;
  font-size: 12px;

  ${SIZES.media.tablet} {
    font-size: 16px;
  }
`;
