import { useNavigate } from "react-router-dom";
import { ReactComponent as HouseOfFriendsLogo } from "assets/icons/logo.svg";
import {
  Content,
  ButtonWrapper,
  SvgWrapper,
  StyledDescription,
  ButtonsContainer,
  ContactUsExternalLink,
  StyledButton,
} from "./MainPage.styled";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { Background } from "components/Background";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { FC } from "react";
import { LOGIN_PAGE, REGISTER } from "urls/frontend";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";

const MainPage: FC = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate(LOGIN_PAGE);
  };

  const handleActivateInvitationClick = () => {
    navigate("/confirm-invitation");
  };

  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Background>
      <Box>
        <Content
          p={8}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <SvgWrapper display="flex" justifyContent="center">
            <HouseOfFriendsLogo />
          </SvgWrapper>
          <StyledDescription variant="caption">
            {translation["title"][selectedLanguage]}
          </StyledDescription>
          <ButtonsContainer
            display="grid"
            justifyContent="center"
            alignItems="center"
            gridGap={2}
          >
            <ButtonWrapper>
              <Button size={ButtonSize.SMALL} onClick={handleLoginClick}>
                {translation["login"][selectedLanguage]}
              </Button>
              <StyledButton
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.SMALL}
                onClick={() => navigate(REGISTER)}
              >
                {translation["getAccess"][selectedLanguage]}
              </StyledButton>
            </ButtonWrapper>
          </ButtonsContainer>
          <StyledDescription variant="caption">
            {translation["wouldYouLike"][selectedLanguage]}{" "}
            <ContactUsExternalLink
              href="mailto:info@houseoffriends.world"
              linkColor="black"
            >
              {translation["contactUs"][selectedLanguage]}
            </ContactUsExternalLink>
          </StyledDescription>
        </Content>
      </Box>
    </Background>
  );
};

export default MainPage;
