import { FC } from "react";
import { HousesListProps } from "./HousesList.d";
import {
  StyledContainer,
  StyledBlurContainer,
  StyledBlurImagesContainer,
} from "./HousesList.styled";
import { HouseItem } from "./HouseItem";
import { RequestFullAccessFooter } from "components/ApplyForMembership/RequestFullAccessFooter";
import House1 from "assets/house-1.jpeg";
import House2 from "assets/house-2.jpeg";
import House3 from "assets/house-3.jpeg";
import { Image } from "components/Image";

const housesExamplesImages = [House1, House2, House3];

export const HousesList: FC<HousesListProps> = ({
  houses,
  demo_token,
  withEditIcon = false,
  appliedForMembership = false,
}) => {
  const userType = localStorage.getItem("user_type") || "";
  const isBasicUserType = !!userType && JSON.parse(userType) === "BASIC";

  return (
    <>
      <StyledContainer display="grid" gridGap={2}>
        {houses.map((house, index) => (
          <HouseItem
            key={isBasicUserType ? index.toString() : house.id}
            houseId={isBasicUserType ? index.toString() : house.id}
            images={house.images}
            images_count={house.images_count}
            title={house.title}
            city={house.location.city}
            country={house.location.country}
            maxGuests={house.attributes_count.max_total_guests}
            minPrice={house.attributes_count.min_price}
            discount={house.attributes_count.discount_rate}
            totalPriceWithoutDiscount={
              house.attributes_count.total_price_without_discount
            }
            totalPrice={house.attributes_count.total_price}
            demo_token={demo_token}
            isFavorite={house.is_bookmarked}
            withEditIcon={withEditIcon}
          />
        ))}
      </StyledContainer>
      {isBasicUserType && (
        <>
          <StyledBlurImagesContainer display="grid" gridGap={2} pt={6.3}>
            {housesExamplesImages.map((image, index) => (
              <Image
                key={`${image}-${index}`}
                src={image}
                variant="gallery"
                alt={`${image}`}
              />
            ))}
          </StyledBlurImagesContainer>
          <StyledBlurContainer />
          <RequestFullAccessFooter isRequestSent={appliedForMembership} />
        </>
      )}
    </>
  );
};
