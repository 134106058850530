import { Box } from "components/Box";
import { addSpacesToNumber } from "global/utils";
import { FC, useState } from "react";
import { COLORS } from "theme";
import { HouseItemProps } from "./HouseItem.d";
import {
  StyledHouseLocation,
  StyledOldPrice,
  StyledCurrentPrice,
  StyledLink,
  StyledHouseDetails,
  EditButton,
  StyledHouseDescription,
  StyledRequestFullAccessButtonContainer,
  StyledMorePhotosImageLink,
} from "./HouseItem.styled";
import { PhotoGallery } from "components/PhotoGallery";
import { withSlider } from "global/hooks/withSlider";
import { Image } from "components/Image";
import { HOST_URL } from "urls/api";
import { generatePath, useNavigate } from "react-router";
import {
  APPLY_FOR_MEMBERSHIP,
  HOUSE_EDIT_PAGE_REDIRECT,
  HOUSE_EXAMPLE_PAGE,
  HOUSE_PAGE,
} from "urls/frontend";
import { getPrice } from "utils/booking";
import { Link } from "components/Link";
import { fetchChangeFavoriteHouse } from "services/Houses";
import { ReactComponent as EditIcon } from "assets/icons/icon_edit.svg";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";
import { Button } from "components/Button";
import { ButtonVariant } from "components/Button/Button.enums";

export const HouseItem: FC<HouseItemProps> = ({
  houseId,
  images,
  images_count,
  title,
  city,
  country,
  maxGuests,
  minPrice,
  discount = 0,
  totalPriceWithoutDiscount,
  totalPrice,
  demo_token,
  variant = "large",
  isFavorite,
  withEditIcon = false,
}) => {
  const profile = useProfile();
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type") || "";
  const isBasicUserType = !!userType && JSON.parse(userType) === "BASIC";
  const appliedForMembership =
    profile?.profileInfo?.applied_for_membership || false;
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const houseLocation = `${city}, ${country}`;
  const houseDetails = `${
    !isBasicUserType ? `${title} | ` : ""
  } Max ${maxGuests} ${translation["guests"][selectedLanguage]}`;

  const redirectPath = demo_token
    ? `${generatePath(HOUSE_EXAMPLE_PAGE, {
        id: houseId,
      })}${demo_token ? `?demo_token=${demo_token}` : ""}`
    : generatePath(HOUSE_PAGE, {
        id: houseId,
      });

  const [isHouseFavorite, setIsHouseFavorite] = useState(isFavorite);

  const changeFavorite = async () => {
    if (!demo_token) {
      const response = await fetchChangeFavoriteHouse(houseId);
      response && setIsHouseFavorite(!isHouseFavorite);
    }
  };

  const showPricePerNight = () => {
    if (!totalPrice && !totalPriceWithoutDiscount && !minPrice) {
      return (
        <StyledCurrentPrice
          variant="h4"
          color={COLORS.typography.headline}
          isSmall={variant === "small"}
        >
          {translation["priceOnRequest"][selectedLanguage]}
        </StyledCurrentPrice>
      );
    }
    return (
      <>
        {discount > 0 && (
          <StyledOldPrice variant="h4" color={COLORS.stroke.main}>
            €
            {totalPrice
              ? totalPriceWithoutDiscount &&
                addSpacesToNumber(totalPriceWithoutDiscount)
              : addSpacesToNumber(minPrice)}
          </StyledOldPrice>
        )}
        <StyledCurrentPrice
          variant="h4"
          color={COLORS.typography.headline}
          isSmall={variant === "small"}
        >
          {totalPrice
            ? `${translation["from"][selectedLanguage]} 
            €${addSpacesToNumber(totalPrice)} ${
                translation["totalPrice"][selectedLanguage]
              }`
            : `${translation["from"][selectedLanguage]} €${addSpacesToNumber(
                discount ? getPrice(minPrice, discount) : minPrice
              )} ${translation["perNight"][selectedLanguage]}`}
        </StyledCurrentPrice>
      </>
    );
  };
  return (
    <>
      {isBasicUserType ? (
        <Box>
          {images.length !== 0 && (
            <Image
              src={images[0] && HOST_URL + images[0]}
              variant="gallery"
              alt={`${location}`}
            />
          )}
          <Box display="flex" justifyContent="space-between">
            <StyledHouseDescription>
              <StyledHouseLocation
                color={COLORS.typography.headline}
                variant="h3"
                isSmall={variant === "small"}
              >
                {houseLocation}
              </StyledHouseLocation>
              <StyledHouseDetails
                isSmall={variant === "small"}
                color={COLORS.typography.body}
                variant="caption"
              >
                {houseDetails}
              </StyledHouseDetails>
            </StyledHouseDescription>
          </Box>
          {!appliedForMembership && (
            <StyledRequestFullAccessButtonContainer pt={2.5}>
              <Button
                variant={ButtonVariant.SECONDARY}
                onClick={() => navigate(APPLY_FOR_MEMBERSHIP)}
              >
                {translation["requestFullAccess"][selectedLanguage]}
              </Button>
            </StyledRequestFullAccessButtonContainer>
          )}
        </Box>
      ) : (
        <StyledLink>
          <PhotoGallery
            slidesPerView={1}
            itemsNumber={images.length}
            isFavorite={isHouseFavorite}
            setIsFavorite={changeFavorite}
          >
            {images.map((image: string, index: number) => {
              const isLastImage = index === images.length - 1;
              const showMoreContent = `${translation["show"][selectedLanguage]} ${images_count} ${translation["photos"][selectedLanguage]}`;
              const ImageWithSlider = withSlider(
                images.length > 1 && isLastImage
                  ? StyledMorePhotosImageLink
                  : Image,
                images.length > 1 && isLastImage
                  ? `${redirectPath}/photos`
                  : redirectPath,
                images.length > 1 && isLastImage ? showMoreContent : undefined
              );
              return (
                <ImageWithSlider
                  key={`${title}-${index}`}
                  src={image && HOST_URL + image}
                  variant={variant === "small" ? "smallGallery" : "gallery"}
                  alt={`${title}-${index}`}
                />
              );
            })}
          </PhotoGallery>
          <Link to={redirectPath}>
            {
              <Box display="flex" justifyContent="space-between">
                <StyledHouseDescription>
                  <StyledHouseLocation
                    color={COLORS.typography.headline}
                    variant="h3"
                    isSmall={variant === "small"}
                  >
                    {houseLocation}
                  </StyledHouseLocation>
                  <StyledHouseDetails
                    isSmall={variant === "small"}
                    color={COLORS.typography.body}
                    variant="caption"
                  >
                    {houseDetails}
                  </StyledHouseDetails>
                </StyledHouseDescription>
                {withEditIcon && (
                  <Link to={HOUSE_EDIT_PAGE_REDIRECT(houseId)}>
                    <EditButton type="button">
                      <EditIcon
                        stroke={COLORS.typography.icons}
                        strokeWidth={1.5}
                        fill="none"
                      />
                    </EditButton>
                  </Link>
                )}
              </Box>
            }
            <Box display="flex" mt={1.5}>
              {showPricePerNight()}
            </Box>
          </Link>
        </StyledLink>
      )}
    </>
  );
};
