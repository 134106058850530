import { Box } from "components";
import { RequestBooking } from "components/Booking/BookingCalculator/BookingCalculator.d";
import { Breadcrumbs } from "components/Breadcrumbs";
import { Amenities } from "components/House/Amenities";
import Bedrooms from "components/House/Bedrooms";
import { CancellationPolicy } from "components/House/CancellationPolicy";
import { HouseBookings } from "components/House/HouseBookings";
import { HouseGallery } from "components/House/HouseGallery";
import { HouseHeader } from "components/House/HouseHeader";
import { HouseMainInfo } from "components/House/HouseMainInfo";
import { HouseOwners } from "components/House/HouseOwners";
import { HouseServices } from "components/House/HouseServices";
import { HouseSummary } from "components/House/HouseSummary";
import { OwnerAdvice } from "components/House/OwnerAdvice";
import { HouseRules } from "components/HouseRules";
import Layout from "components/Layout";
import { Recommendations } from "components/Recommendations";
import { useWindowSize } from "global/hooks/useWindowSize";
import { formatDate } from "global/utils";
import { FC, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import {
  fetchHouse,
  fetchSendBookingRequest,
  fetchUpdateBookingRequest,
} from "services/House";
import { HouseProps } from "types/house.d";
import {
  MainContainer,
  StyledContainer,
  LeftContainer,
  RightContainer,
  StyledRulesContainer,
  TopContainer,
  MobileHeaderContainer,
  StyledBackButton,
  StyledAccordionArrowIcon,
} from "./House.styled";
import { Vicinity } from "components/Vicinity";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { Loader } from "components/Loader/Loader";
import { fetchChangeFavoriteHouse } from "services/Houses";
import { OnYourDemand } from "components/House/OnYourDemand";
import SeasonalPrices from "components/House/SeasonalPrices/SeasonalPrices";
import Swipe from "components/Swipe/Swipe";
import { useCalculator } from "context/Calculator";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const House: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useWindowSize();
  const [house, setHouse] = useState<HouseProps>();
  const [editedBooking, setEditedBooking] = useState("");
  const [isOpenHouseGallery, setOpenHouseGallery] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const {
    calculatorHouseId,
    adults,
    children,
    infants,
    startDate,
    endDate,
    resetCalculator,
  } = useCalculator();

  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const isAmenitiesSectionEmpty =
    house?.amenities_by_category &&
    Object.keys(house?.amenities_by_category).length === 0;
  const isHouseSummarySectionEmpty =
    house?.description && house?.description.length === 0;
  const isOwnerAdviceSectionEmpty =
    !house?.owneradvice ||
    !house?.owneradvice?.description ||
    house?.owneradvice?.description.length === 0;
  const isRecommendationsSectionEmpty =
    house?.map_recommendations &&
    Object.keys(house?.map_recommendations).length === 0;
  const isServicesSectionEmpty =
    house?.recommendations_without_map &&
    Object.keys(house?.recommendations_without_map).length === 0;
  const isOwnersSectionEmpty =
    house?.ownerinfo && house?.ownerinfo.length === 0;
  const isRulesSectionEmpty = house?.rules && house?.rules.length === 0;
  const isOnYourDemandSectionEmpty =
    house?.arranged_additionally && house?.arranged_additionally.length === 0;
  const isCancellationSectionEmpty =
    (house?.cancellation_policy && house?.cancellation_policy.length === 0) ||
    house?.cancellation_policy === null;
  const isBedroomSectionEmpty = house?.bedrooms && house?.bedrooms.length === 0;
  const isSeasonPricesEmpty =
    house?.seasonal_prices && house?.seasonal_prices.length === 0;
  const isVicinitySectionEmpty =
    house?.vicinities && house?.vicinities.length === 0;
  const [isLoaded, setIsLoaded] = useState(false);
  const demo_token = searchParams.get("demo_token");
  const [isHouseFavorite, setIsHouseFavorite] = useState<boolean>(false);

  const getHouseData = async () => {
    const response = id && (await fetchHouse(id, demo_token));
    response && setHouse(response.parsedBody);
    response && setIsHouseFavorite(response.parsedBody.is_bookmarked);
    setIsLoaded(true);
  };

  useEffect(() => {
    getHouseData();
  }, [profile?.selectedLanguage]);

  const changeFavorite = async () => {
    if (!demo_token) {
      house && (await fetchChangeFavoriteHouse(house.id));
      setIsHouseFavorite(!isHouseFavorite);
    }
  };
  const sendBookingRequest = async () => {
    const isNewBookingRequest = editedBooking.length === 0;
    const data = {
      house: id,
      check_in_date: startDate && formatDate(startDate, "dd.MM.yyyy"),
      check_out_date: endDate && formatDate(endDate, "dd.MM.yyyy"),
      adults: adults,
      children: children,
      infants: infants,
    };
    const response = isNewBookingRequest
      ? await fetchSendBookingRequest(data)
      : await fetchUpdateBookingRequest(editedBooking, data);

    if (response.ok) {
      isNewBookingRequest
        ? toast.success(translation["bookingRequestSent"][selectedLanguage])
        : toast.success(translation["bookingRequestUpdated"][selectedLanguage]);
      getHouseData();
      resetCalculator(id ?? "");
    } else {
      toast.error(translation["bookingRequestError"][selectedLanguage]);
    }
  };

  return (
    <Layout isHousePage>
      {isLoaded ? (
        house && (
          <MainContainer m={2.3}>
            {isMobile && (
              <MobileHeaderContainer>
                <StyledBackButton size="medium" onClick={() => navigate(-1)}>
                  <StyledAccordionArrowIcon />
                </StyledBackButton>
              </MobileHeaderContainer>
            )}
            <TopContainer display="grid" gridGap={3} mb={4}>
              {!isTablet && <Breadcrumbs currentPageTitle={house.title} />}
              <HouseHeader
                title={house.title}
                city={house.location.city}
                country={house.location.country}
                max_total_guests={house.attributes_count.max_total_guests}
                living_space={house.attributes_count.living_space}
                bedrooms_num={house.attributes_count.bedrooms}
                bathrooms_num={house.attributes_count.bathrooms}
                isFavorite={isHouseFavorite}
                setIsFavorite={changeFavorite}
              />
              <HouseGallery
                isOpen={isOpenHouseGallery}
                setOpen={setOpenHouseGallery}
                images={house.images}
                images_count={house.images_count}
                mainPhoto={house.main_image}
                houseId={house.id}
              />
              {isMobile && (
                <HouseMainInfo
                  max_total_guests={house.attributes_count.max_total_guests}
                  bedrooms_num={house.attributes_count.bedrooms}
                  bathrooms_num={house.attributes_count.bathrooms}
                  living_space={house.attributes_count.living_space}
                />
              )}
            </TopContainer>
            <StyledContainer mb={6.3}>
              <LeftContainer>
                {!isHouseSummarySectionEmpty && (
                  <HouseSummary
                    description={house.description}
                    keyInfos={house.key_infos}
                  />
                )}
                {!isAmenitiesSectionEmpty && (
                  <Amenities content={house.amenities_by_category} />
                )}
              </LeftContainer>
              <RightContainer>
                {!isOpenHouseGallery && (
                  <HouseBookings
                    isPrivate={house.is_owner}
                    bookingRequests={house.bookings}
                    adultsPrice={house.price.per_adult}
                    childPrice={house.price.per_child}
                    infantPrice={house.price.per_baby}
                    maxAdults={house.attributes_count.max_adults}
                    maxChildren={house.attributes_count.max_children}
                    maxGuests={house.attributes_count.max_total_guests}
                    discount={house.price.discount_rate}
                    discountDescription={house.price.discount_description}
                    cap={house.price.cap}
                    minimumPricePerNight={house.price.minimum_price_per_night}
                    minimumNights={house.price.minimum_nights}
                    blockedDates={house.blocked_dates}
                    bookedDates={house.booked_dates}
                    editedBooking={editedBooking}
                    setEditedBooking={setEditedBooking}
                    sendBookingRequest={sendBookingRequest}
                    maxRequests={house.max_requests}
                    seasonalPrices={house.seasonal_prices}
                  />
                )}
              </RightContainer>
            </StyledContainer>
            <Box display="grid" gridGap={6.3} mb={4.3}>
              {!isBedroomSectionEmpty && <Bedrooms bedrooms={house.bedrooms} />}
              {!isVicinitySectionEmpty && house && (
                <Vicinity vicinity={house.vicinities[0]} />
              )}
              {!isRecommendationsSectionEmpty && (
                <Recommendations
                  tabsData={house.map_recommendations}
                  houseLatitude={house.location.latitude}
                  houseLongitude={house.location.longitude}
                />
              )}
              {!isServicesSectionEmpty && (
                <HouseServices tabsData={house.recommendations_without_map} />
              )}
              {!isOwnersSectionEmpty && (
                <HouseOwners ownersInfo={house.ownerinfo} />
              )}
              {!isOwnerAdviceSectionEmpty && (
                <OwnerAdvice ownerAdvice={house.owneradvice.description} />
              )}
              {!isSeasonPricesEmpty && house?.show_seasonal_prices && (
                <Swipe scrollOffset={120}>
                  <SeasonalPrices seasonal_prices={house.seasonal_prices} />
                </Swipe>
              )}
              {(!isRulesSectionEmpty || !isCancellationSectionEmpty) && (
                <StyledRulesContainer p={4} display="grid" gridGap={7}>
                  {!isOnYourDemandSectionEmpty && (
                    <OnYourDemand
                      arrangedAdditionally={house.arranged_additionally}
                    />
                  )}
                  {!isRulesSectionEmpty && <HouseRules rules={house.rules} />}
                  {!isCancellationSectionEmpty && (
                    <CancellationPolicy
                      text={house.cancellation_policy ?? ""}
                    />
                  )}
                </StyledRulesContainer>
              )}
            </Box>
          </MainContainer>
        )
      ) : (
        <Loader />
      )}
    </Layout>
  );
};
