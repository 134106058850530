import { Gallery } from "components/Gallery/Gallery";
import { Image } from "components/Image";
import { useWindowSize } from "global/hooks/useWindowSize";
import { FC, useState } from "react";
import { COLORS, SIZES } from "theme";
import { HOST_URL } from "urls/api";
import { HouseGalleryProps } from "./HouseGallery.d";
import {
  StyledContainer,
  StyledFirstImage,
  StyledSecondImage,
  StyledThirdImage,
  StyledDesktopLink,
  StyledTabletLink,
  StyledDesktopLinkText,
  StyledDesktopImageLink,
} from "./HouseGallery.styled";
import { ImagesListType } from "react-spring-lightbox";
import { useNavigate } from "react-router";
import { HOUSE_PHOTOS_PAGE_REDIRECT } from "urls/frontend";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const HouseGallery: FC<HouseGalleryProps> = ({
  images,
  images_count,
  mainPhoto,
  isOpen,
  setOpen,
  houseId,
}) => {
  const userProfile = useProfile();
  const selectedLanguage = userProfile?.selectedLanguage || getLanguage();
  const { width } = useWindowSize();
  const isTablet = width >= SIZES.deviceSize.tablets;
  const imagesToShow = Object.values(images).flat();
  const imagesWithoutDuplicates = imagesToShow.filter(
    (image) => image !== mainPhoto
  );
  const slider: ImagesListType = imagesToShow.map((image) => ({
    src: `${HOST_URL}${image}`,
    alt: "",
  }));
  const [secondShowedImage, thirdShowedImage, fourthShowedImage] =
    imagesWithoutDuplicates;
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const navigate = useNavigate();
  return (
    <StyledContainer display="grid">
      {!isTablet ? (
        <>
          <StyledTabletLink
            onClick={() => {
              const path = HOUSE_PHOTOS_PAGE_REDIRECT(houseId);
              navigate(path);
            }}
          >
            {`${translation["show"][selectedLanguage]} ${imagesToShow.length} ${translation["photos"][selectedLanguage]}`}
          </StyledTabletLink>
          <Image variant="galleryBig" src={`${HOST_URL}${mainPhoto}`} />
        </>
      ) : (
        <>
          <StyledFirstImage
            onClick={() => (setOpen(true), setCurrentImageIndex(0))}
          >
            <Image
              withBorder
              variant="galleryBig"
              src={mainPhoto && `${HOST_URL}${mainPhoto}`}
            />
          </StyledFirstImage>
          <StyledSecondImage
            onClick={() =>
              secondShowedImage && (setOpen(true), setCurrentImageIndex(1))
            }
          >
            <Image
              withBorder
              variant="galleryMedium"
              src={secondShowedImage && `${HOST_URL}${secondShowedImage}`}
            />
          </StyledSecondImage>
          <StyledThirdImage
            onClick={() =>
              thirdShowedImage && (setOpen(true), setCurrentImageIndex(2))
            }
          >
            <Image
              withBorder
              variant="gallerySmall"
              src={thirdShowedImage && `${HOST_URL}${thirdShowedImage}`}
            />
          </StyledThirdImage>
          {fourthShowedImage ? (
            <StyledDesktopLink
              onClick={() => {
                const path = HOUSE_PHOTOS_PAGE_REDIRECT(houseId);
                navigate(path);
              }}
            >
              <StyledDesktopLinkText
                variant="caption"
                color={COLORS.typography.secondary.main}
              >
                {`${translation["show"][selectedLanguage]} ${images_count} ${translation["photos"][selectedLanguage]}`}
              </StyledDesktopLinkText>

              <StyledDesktopImageLink
                withBorder
                variant="gallerySmall"
                src={fourthShowedImage && `${HOST_URL}${fourthShowedImage}`}
              />
            </StyledDesktopLink>
          ) : (
            <Image
              withBorder
              src={fourthShowedImage && `${HOST_URL}${fourthShowedImage}`}
              variant="gallerySmall"
            />
          )}
        </>
      )}
      <Gallery
        images={slider}
        isOpen={isOpen}
        setOpen={setOpen}
        currentImageIndex={currentImageIndex}
        setCurrentImageIndex={setCurrentImageIndex}
      />
    </StyledContainer>
  );
};
